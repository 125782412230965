import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from '../components/seo';

const Indywidualna = () => {
    return (
        <Layout>
            <Seo title="Warsztaty Dla Instruktorów"/>
            <section className="subof-container">
                <section className="subof-intro">
                    <StaticImage 
                        src='../images/main_fourth.jpg'
                        alt="poza pilates" 
                        loading="eager"
                        className='subof-intro_picture'
                    />
                    <h1 className="subof-intro_title">Warsztaty Dla Instruktorów</h1>
                    <h2 className="subof-intro-subtitle">Oferta programów rozwojowych i szkoleń dla instruktorów różnych metod pracy z ciałem oraz możliwość współpracy partnerskiej.
                    </h2>
                </section>
                <section className="subof-hero">
                    <p className="subof-hero_text">Zapraszamy instruktorów różnych metod pracy z ciałem do zapoznania się z naszymi kursami, szkoleniami, spotkaniami w formie Mastermind oraz programami partnerskimi.</p>
                    <p className="subof-hero_text">Może podoba Ci się nasza przestrzeń i chciałbyś zorganizować w niej swoje spotkanie czy warsztaty z różnych dziedzin lub skorzystać z naszej infrastruktury Pilates do pracy ze swoimi Klientami?</p>
                    <p className="subof-hero_text">A może jesteś zainteresowany specjalistycznym programem stażowym Pilatesu terapeutycznego lub skorzystaniem z rozwojowej superwizji dla instruktorów?</p>
                    <p className="subof-hero_text">Nasza oferta dla instruktorów różnych metod pracy z ciałem to między innymi:</p>
                    <p className="subof-hero_text">KURSY I PROGRAMY ROZWOJOWE:</p>
                    <ul className="subof-hero_list">
                        <li>Kurs „Bio-Logika w Pilatesie - Pilates Teacher Excellence Program” (edycja 2024/2025) i poszczególne moduły tematyczne:<br/>
                        - Diagnostyka funkcjonalna w Piatesie<br/>
                        - Biomechanika w Pilatesie – anatomia i zasady biomechaniki<br/>
                        - Neurologia funkcjonalna w Pilatesie<br/>
                        - Fizjologia oddechu w Pilatesie<br/>
                        - Techniki dodatkowe w Pilatesie<br/>
                        - Logika doboru ćwiczeń w Pilatesie pod kątem celu<br/>
                        - Pedagogika w Pilatesie<br/>
                        - Psychologia obsługi Klienta w Pilatesie<br/>
                        </li>
                        <li>Punktowe szkolenia tematyczne dla instruktorów Pilates<br/>
                        - Diagnostyka dokumentacyjna w Pilatesie<br/>
                        - Wzorzec chodu – obserwacja, analiza, dysfunkcje i rozwiązania<br/>
                        - Pilates w pracy z osobami starszymi<br/>
                        - Testy siły mięśniowej w Pilatesie<br/>
                        - Testy neurologiczne w Pilatesie<br/>
                        - Pilates w wybranych schorzeniach<br/>
                        - Pilates w pracy z kobietami w ciąży i po porodzie<br/>
                        </li>
                        <li>Szkolenie „Biomechanika w praktyce - warsztaty dla trenerów różnych metod pracy z ciałem”</li>
                        <li>Szkolenie „Neuroimmunologia w praktyce”</li>
                        <li>Szkolenie „Obsługa Klienta indywidualnego w obszarze zdrowia”</li>
                        <li>Spotkania grupy rozwojowej Pilates Mastermind</li>
                        <li>Roczny indywidualny program stażowy Pilatesu Terapeutycznego</li>
                        <li>Superwizje rozwojowe dla instruktorów metody Pilates</li>
                    </ul>
                    <p className="subof-hero_text">INNA WSPÓŁPRACA:</p>
                    <ul className="subof-hero_list">
                        <li>Wynajem sali z maszynami i sprzętem Pilatesowym w naszym Studio (Reformer, Reformer with Tower, Cadillac, Wunda Chair, Combo Chair, Ladder Barrel, Spine Corrector, itd.)</li>
                        <li>Porozumienia barterowe na wymianę usług</li>
                        <li>Wspólne inicjatywy edukacyjne</li>
                        <li>System rekomendacji</li>
                    </ul>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Zapraszamy do rozmowy o współpracy.</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Ponieważ działamy bardzo dynamicznie, wszystkie informacje o naszych inicjatywach i aktualnych wydarzeniach znajdziesz na naszym profilu Facebook w zakładce "Wydarzenia".</p>
                    </div>
                    <div className="subof-hero_text-container">
                        <p className="subof-hero_text">Aktualny kurs dla instruktorów "Bio-logika w Pilatesie - Pilates Teacher Excellence Program" sezon szkoleniowy 2024/2025</p>
                        <a href='/documents/Regulamin_kursu_biologika_2024_2025.pdf' download="Regulamin Kursu dla instruktorów edycja II, rok 2024 2025" className='subof-hero_text course course-download'>POBIERZ REGULAMIN: KURS BIO-LOGIKA &#8628;</a>
                    </div>
                    <div className="sub-hero_link-container">
                        <a href="https://www.facebook.com/pilatesterapeutyczny/events" target="_blank" className="sub-hero_link-link">WYDARZENIA</a>
                    </div>
                    <div className="sub-hero_link-container">
                        <Link to="/kontakt" className="sub-hero_link-link">KONTAKT</Link>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default Indywidualna;